import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    padding: 16,
    transition: "all .25s linear",
    "&:hover, &:focus": {
      boxShadow: "-1px 1px 20px 10px rgba(0,0,0,0.1)",
    },
    "@media (max-width:768px)": {
      width: "100%",
      padding: 8,
    },
  },
  imgWrapper: {
    width: "33.33%",
  },
  img: {
    width: "100%",
    borderRadius: "50%",
    height: "auto",
    border: "0",
  },
  textContainer: {
    width: "66.67%",
  },
  pos: {
    marginTop: 12,
  },
}))

function CardPerson({ imageData, imgAlt, name, designation, description }) {
  const classes = useStyles()

  return (
    <Card className={classes.root} elevation={0}>
      <CardContent className={classes.imgWrapper}>
        <Img className={classes.img} fluid={imageData} alt={imgAlt} />
      </CardContent>
      <CardContent className={classes.textContainer}>
        <Typography variant="h5" component="h4">
          {name}
        </Typography>
        <Typography variant="subtitle1" component="h5">
          {designation}
        </Typography>
        <Typography className={classes.pos} variant="body1" component="p">
          {description}
        </Typography>
      </CardContent>
    </Card>
  )
}

CardPerson.propTypes = {
  imageData: PropTypes.object.isRequired,
  imgAlt: PropTypes.string,
  name: PropTypes.string.isRequired,
  designation: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default CardPerson
