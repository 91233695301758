import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../../layout/layout"
import SEO from "../../components/seo"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Hero from "../../components/hero/background-color"
import PageWrapper from "../../components/page-wrapper"
import SubsectionImgLeft from "../../components/subsection-img-left"
import SubsectionImgRight from "../../components/subsection-img-right"
import CTA from "../../components/cta"
import Spacer from "../../components/spacer"
import { Typography, Grid } from "@material-ui/core"
import CardPerson from "../../components/card-person"
import content from "../../locales/en/about-who-we-are.json"

const useStyles = makeStyles({
  root: {
    width: "85%",
    margin: "0 auto",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "120px",
  },
  header: {
    width: "70%",
    marginTop: "36px",
    marginBottom: "36px",
    "@media (max-width:768px)": {
      width: "100%",
      marginTop: "24px",
      marginBottom: "24px",
    },
  },
  gridContainer: {
    marginTop: "48px",
    marginBottom: "48px",
    "@media (max-width:768px)": {
      marginTop: "24px",
      marginBottom: "24px",
    },
  },
})

export default function About({ pageContext, location }) {
  const data = useStaticQuery(graphql`
    query AboutContent {
      klPic: file(
        relativePath: { eq: "who-we-are/CKL-profile-pic-square.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      jackPic: file(
        relativePath: { eq: "who-we-are/jack-profile-pic-square.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      peterPic: file(
        relativePath: { eq: "who-we-are/peter-thai-profile-pic-square.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nhanPic: file(
        relativePath: { eq: "who-we-are/prof-nhan.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const classes = useStyles()
  const klImage = data.klPic.childImageSharp.fluid
  const jackImage = data.jackPic.childImageSharp.fluid
  const peterImage = data.peterPic.childImageSharp.fluid
  const nhanPic = data.nhanPic.childImageSharp.fluid

  content.section.people[0].imageData = klImage
  content.section.people[1].imageData = jackImage
  content.section.people[2].imageData = peterImage
  content.section.people[3].imageData = nhanPic

  const {
    breadcrumb: { crumbs },
  } = pageContext
  const customCrumbLabel = crumbs[crumbs.length - 1].crumbLabel.replace(
    new RegExp("-", "g"),
    " "
  )
  return (
    <>
      <Layout location={location}>
      <SEO title={content.seo.title} />
        <Hero textTitle={content.hero.heading} />
        <PageWrapper>
          <div className={classes.root}>
            <Breadcrumb crumbs={crumbs} crumbLabel={customCrumbLabel} />
            <div className={classes.headerContainer}>
              <Typography className={classes.header} variant="body1">
                {content.sectionText.description}
              </Typography>
            </div>
            <Typography className={classes.header} variant="h2">
              {content.section.heading}
            </Typography>
            <Grid
              className={classes.gridContainer}
              container
              justify="center"
              direction="row"
              alignItems="center"
              spacing={1}
            >
              {content.section.people.map((person, index) => (
                <Grid key={index} item xs={12} sm={6}>
                  <CardPerson
                    name={person.name}
                    designation={person.designation}
                    description={person.description}
                    imageData={person.imageData}
                    imgAlt={person.imageAlt}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </PageWrapper>
        {/* <SubsectionImgLeft
            imageData={subsectionImgLeft}
            imageAlt={''}
            textTitle={content.subsectionImgLeft.heading}
            textDescription={content.subsectionImgLeft.description} />
          <Spacer />
          <SubsectionImgRight
            imageData={subsectionImgRight}
            imageAlt={''}
            textTitle={content.subsectionImgRight.heading}
            textDescription={content.subsectionImgRight.description} /> */}
        <Spacer />
        <CTA />
      </Layout>
    </>
  )
}
