import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    display: "block",
    height: "60vh",
    background:
      "linear-gradient(315deg, rgb(153, 204, 51, 0.9) 0%, rgba(46,82,84, 0.9) 100%)",
  },
  fullHeightRoot: {
    display: "block",
    height: "100vh",
    background:
      "linear-gradient(315deg, rgb(153, 204, 51, 0.9) 0%, rgba(46,82,84, 0.9) 100%)",
  },
  textWrapper: {
    height: "100%",
    width: "100%",
    zIndex: 1,
    margin: "0 auto",
  },
  wrapper: {
    height: "100%",
    width: "100%",
    maxWidth: "1440px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  contentWrapper: {
    display: "flex",
    height: "100%",
    width: "85%",
    marginLeft: "7%",
  },
  content: {
    width: "55%",
    alignSelf: "center",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  darkContent: {
    width: "55%",
    alignSelf: "center",
    padding: "36px",
    borderRadius: "16px",
    backgroundColor: "rgba(255,255,255,0.8)",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  title: {
    color: "#fff",
    zIndex: "2",
  },
  darkTitle: {
    color: theme.palette.primary.main,
    zIndex: "2",
  },
  description: {
    marginTop: 12,
    color: "#fff",
    zIndex: "2",
  },
  darkDescription: {
    marginTop: 12,
    color: theme.palette.primary.main,
    zIndex: "2",
  },
}))

const Hero = ({ imageData, textTitle, textDescription, fullHeight, dark }) => {
  const classes = useStyles()
  return (
    <div className={fullHeight ? classes.fullHeightRoot : classes.root}>
      <div className={classes.textWrapper}>
        <div className={classes.wrapper}>
          <div className={classes.contentWrapper}>
            <div className={dark ? classes.darkContent : classes.content}>
              <Typography
                className={dark ? classes.darkTitle : classes.title}
                variant="h2"
                component="h1"
              >
                {textTitle}
              </Typography>
              {textDescription ? (
                <Typography
                  className={
                    dark ? classes.darkDescription : classes.description
                  }
                  variant="body1"
                >
                  {textDescription}
                </Typography>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Hero.defaultProps = {
  textTitle: `SubsectionHero Title Placeholder`,
  textDescription: ``,
  fullHeight: false,
}

Hero.propTypes = {
  textTitle: PropTypes.string.isRequired,
  textDescription: PropTypes.string,
  fullHeight: PropTypes.bool.isRequired,
}

export default Hero
