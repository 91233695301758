import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import Button from "../button"
// import BackgroundImage from "gatsby-background-image";
// import ContactModal from '../modal-contact-form'

const useStyles = makeStyles(theme => ({
  root: {
    display: "block",
    height: "35vh",
  },
  imgWrapper: {
    display: "block",
    paddingTop: 0,
    position: "relative",
    height: "inherit",
    backgroundColor: theme.palette.primary.main,
  },
  wrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    width: "55%",
    alignSelf: "center",
    textAlign: "center",
    "@media (max-width:768px)": {
      width: "90%",
    },
  },
  title: {
    color: "#fff",
  },
  description: {
    marginTop: "16px",
    color: "#fff",
  },
  buttonContainer: {
    marginTop: "24px",
  },
}))

const CTA = ({
  imageData = null,
  heading = "How can we help you?",
  description = "Get in touch with us to find out more.",
  buttonTitle = "Contact Us",
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.imgWrapper}>
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <Typography className={classes.title} variant="h2" component="h1">
              {heading}
            </Typography>
            <Typography className={classes.description} variant="body1">
              {description}
            </Typography>
            <div className={classes.buttonContainer}>
              <Button
                onClick={() =>
                  (window.location.href = "mailto:info@dpaerogels.com")
                }
                label={buttonTitle}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CTA
