import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles({
  root: {
    width: "100vw",
    display: "flex",
    flexDirection: "row",
    marginLeft: "calc(-50vw + 50%)",
    "@media (max-width:768px)": {
      flexDirection: "column-reverse",
    },
  },
  img: {
    borderRadius: "4px",
    width: "50%",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  textContainerWrapper: {
    display: "flex",
    width: "50%",
    justifyContent: "flex-end",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  textContainer: {
    display: "flex",
    width: "45em",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#fff",
    "@media (max-width:768px)": {
      marginTop: "24px",
      width: "100%",
    },
  },
  textWrapper: {
    width: "70%",
    marginLeft: "14%",
    "@media (max-width:768px)": {
      width: "100%",
      paddingLeft: "5%",
      paddingRight: "5%",
      marginLeft: "0",
    },
  },
  textBody: {
    marginTop: "24px",
  },
})

const SubsectionImgRight = ({
  imageData,
  imageAlt,
  textTitle,
  textDescription,
}) => {
  const classes = useStyles()

  return (
    <section className={classes.root}>
      <div className={classes.textContainerWrapper}>
        <div className={classes.textContainer}>
          <div className={classes.textWrapper}>
            <Typography variant="h3">{textTitle}</Typography>
            <Typography className={classes.textBody} variant="body1">
              {textDescription}
            </Typography>
          </div>
        </div>
      </div>
      <Img className={classes.img} fluid={imageData} alt={imageAlt} />
    </section>
  )
}

SubsectionImgRight.defaultProps = {
  imageUrl: ``,
  imageAlt: ``,
  textTitle: `SubsectionImgRight Title Placeholder`,
  textDescription: `SubsectionImgRight description placeholder.`,
}

SubsectionImgRight.propTypes = {
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  textTitle: PropTypes.string.isRequired,
  textDescription: PropTypes.string.isRequired,
}

export default SubsectionImgRight
