import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  root: {
    display: "block",
  },
  buttonContainer: {
    minWidth: "120px",
  },
  buttonText: {
    color: "#fff",
    fontFamily: "DINPro",
    fontSize: "1.125rem",
    textTransform: "none",
    textDecoration: "none",
    fontWeight: 700,
    "@media (max-width:768px)": {
      fontSize: "1rem",
    },
  },
}))

const ButtonClickable = ({ label, onClick, color = "secondary" }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color={color}
        className={classes.buttonContainer}
        onClick={onClick}
      >
        <Typography className={classes.buttonText}>{label}</Typography>
      </Button>
    </div>
  )
}

export default ButtonClickable
